.container {
  position: relative;
  width: 100%;
  display: flex;
  height: 60px;
  border-radius: 4px;
  padding: 5px 12px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;

  &__focused {
    border-color: #3e3e3e;
  }

  &__input {
    flex: 1;
    font-size: 18px;
    outline: none;
    color: #303030;
    box-sizing: border-box;
    border: none;
    height: 100%;
    padding: 0;
    line-height: 28px;
    background-color: transparent;

    &__focused {
      height: 28px;
      margin-top: auto;
    }

    &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
      color: #303030;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 24px;
    color: #999;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    &.labelActive {
      top: 5px;
      left: 12px;
      font-size: 14px;
      line-height: 20px;
      transform: translateY(0);
    }
  }
}
