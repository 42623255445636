@import '../mixins/breakpoints';
@import '../utils/default';

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.disableScroll {
  overflow: hidden;
  height: auto;
}

.biab_body .biab_seo-settings .biab_seo-settings-content {
  @include defaultStyles;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.biab_body {
  font-size: 12px;
  line-height: 14px;
  color: #273a47;
  overflow-y: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.tooltip-start {
  text-align: start;
}

.tooltip-icon {
  font-size: 12px;
}

.skeleton_page_wrapper {
  position: relative;
}

.react-datepicker-popper {
  z-index: 5;
}
