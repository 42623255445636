@import 'styles/variables/variables.scss';

.checkboxField {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    .checkboxField__checkbox {
      border-color: $border-black;
    }

    input:checked + .checkboxField__checkbox {
      background-color: $blue-dark;
      border-color: transparent;
    }
  }

  &__checkbox {
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    border: 1px solid $border-dark-gray;
    border-radius: 2px;
    position: relative;
    background-color: #fff;
    transition: all 0.1s ease;
    font-size: 8px;
    flex-shrink: 0;

    &::before {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__mobile {
      width: 24px;
      height: 24px;
      border-radius: 4px;

      &::before {
        font-size: 10px;
      }
    }

    &__halfChecked {
      border-color: transparent;

      &::before {
        content: '';
        height: 2px;
        width: 8px;
        background: #FFFFFF;
        border-radius: 2px;
      }
    }
  }

  input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;

    &:checked + .checkboxField__checkbox {
      background-color: $blue;
      border-color: transparent;
    }

    &:focus-visible + .checkboxField__checkbox {
      outline: 2px solid -webkit-focus-ring-color;
      outline-offset: 1px;
    }
  }
}
