@import 'styles/variables/variables';

.headerNavigationMobileItem {
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-width: 48px;
    height: 43px;
    border-radius: 2px;
    color: #dcdcdc;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-size: $smaller;
    border: solid 1px #525252;
    gap: 5px;
    padding: 2px 4px;

    i {
      display: flex;
      align-items: center;
      height: 50%;
    }
  }

  &__howToLink {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  &.active {
    background-color: #343434;
  }

  &__icon {
    display: inherit;

    &:before {
      font-size: $middle;
    }
  }

  &__label {
    line-height: normal;
    white-space: nowrap;
  }

  &__cacheOut {
    font-family: 'FontAwesome';
    position: relative;
    overflow: hidden;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 1px solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0 auto;
    &:before {
      content: '\f0c8';
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 8px;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  animation: modalFadeOut 1.5s;
  opacity: 0.5;
  z-index: 99;
}
