.datepickerCustomheader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: #666;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  button {
    position: absolute;
    left: 0;
    border: none;
    font-size: 0;
    padding: 0;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: none;
    cursor: pointer;

    &:not(:disabled):hover {
      background-color: #ECECEC;
    }
    &:disabled {
      cursor: initial;
    }

    &:last-child {
      left: auto;
      right: 0;
    }
  }

  i, i:before {
    position: static !important;
  }

  &__arrowLeft, &__arrowRight {
    font-size: 16px !important;
    top: 0 !important;
  }

  &__arrowLeft {
    left: 0 !important;
  }

  &__arrowRight {
    left: 0 !important;
  }
}
