.tableHeader {
  display: flex;
  align-items: center;
  background: #F5F5F5;
  color: #333333;
  padding: 5px 0 5px 20px;
  border-radius: 4px;
  gap: 20px;
  height: 40px;

  &__wrapper {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__whiteSpace {
    height: 20px;
    background-color: #FFFFFF;
  }

  &__stickied {
    box-shadow: 0 2px 8px 0 rgba(24, 30, 92, 0.15);
  }

  &__less1200 {
    padding-right: 20px;
  }

  &__item {
    font-size: 12px;
    line-height: 15px;
    color: #666;
    margin: 0;

    &__40 {
      width: 40px;
    }

    &__50 {
      width: 50px;
    }

    &__60 {
      width: 60px;
    }

    &__80 {
      width: 80px;
    }

    &__status {
      width: 110px;
      text-align: center;
    }

    &__description {
      flex: 1;
    }

    &__right {
      text-align: right;
    }
  }
}

.noData {
  text-align: center;
  font-size: 14px;
  margin: 0;
  height: 200px;
  line-height: 200px;
}

.alternativeLayOdds {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
  color: #666;
}

.skeleton {
  &__container {
    height: 100px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #ddd;

    &:last-of-type {
      margin-bottom: 100px;
    }
  }

  &__item {
    height: 20px;

    &__60 {
      width: 60px;
    }

    &__80 {
      width: 80px;
    }

    &__200 {
      width: 200px;
    }
  }
}
