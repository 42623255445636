.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
  padding-bottom: 8px;

  &__row {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &__center {
      justify-content: center;
    }

    &__left {
      justify-content: left;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;

    &__left {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #303030;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.periodLabel {
  margin: 0;
  font-size: 16px;
  font-weight: 700 !important;
  color: #303030;
}

.productTypeLabel {
  margin: 0;
  font-size: 14px;
  color: #666666;
}

.listHeader {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    gap: 4px;

    > * {
      min-height: 26px;

      &:last-child {
        min-height: 42px;
        display: flex;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
