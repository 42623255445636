.actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;

  &__action {
    all: unset;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    padding: 2px;
    color: #303030;
    cursor: pointer;
    background-color: #ececec;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    box-sizing: border-box;

    &__secondary {
      border: 1px solid transparent;
    }

    &:hover {
      background-color: #ddd;
    }

    &__mobile {
      height: 42px;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__edit {
    width: 100%;
    margin-top: 15px;
  }

  &__save {
    font-weight: 700 !important;
    background-color: #ffb80c;
    border-color: #ffb80c;

    &:focus {
      border: solid 2px #ff8624;
    }

    &:hover {
      background-color: #ffb80c;
    }

    &:disabled {
      background-color: #fad06b;
      border-color: #fad06b;
      color: #666;
      cursor: initial;

      &:hover {
        background-color: #fad06b;
      }
    }
  }
}

i.actions__save__loading {
  color: #303030;
  font-size: 16px;

  &__mobile {
    font-size: 18px;
  }
}
