@import 'styles/mixins/breakpoints';

// Fix issue with scroll content to top on middle section
// Parent component ( .scrollableContent ) has overflow: auto;
.contentWrapScroll {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.contentWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex: 1;

  @include xl {
    overflow: auto;
  }
  @include md {
    overflow: visible;
    border-left: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.noBottomPadding {
  min-height: auto !important;
  padding-bottom: 0 !important;
}

.fullHeight {
  height: 100%;
}

.wrapperNoNav {
  overflow: auto;
}

.wrapper {
  display: flex;

  @include md {
    padding-bottom: 45px;
  }

  &__classicNavigation {
    @include md {
      padding-bottom: 53px;
    }
  }

  &__withoutSideBars {
    .content {
      width: 100%;
      border-left: none;
    }
  }
}
