.goBack {
  &__button {
    all: unset;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #666;
    cursor: pointer;
    box-sizing: border-box;

    &:active {
      background-color: #666666;
    }
  }

  &__icon {
    color: inherit;
    font-size: 24px;
  }
}
