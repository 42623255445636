@import "styles/variables/variables";

.headerNavigationItem {
  &__icon {
    font-size: $regular;
    vertical-align: middle;
    color: inherit !important;
  }

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.cashOutIcon {
  &::before {
    width: 5.5px !important;
  }
}

.cashOutIconOrbit {
  &::before {
    width: 5.5px !important;
  }
}

.asianHiddenElement {
  display: none;
}

i.tickIcon {
  margin-left: auto;

  &:before {
    color: #303030;
  }
}
